import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Eva from "../../components/pages/eva-funk/eva-funk"
import AccessWrapper from '../../components/pages/AccessWrapper'


// console.log(list)

const EvaFunk = ({ data}) => {
  const story = data.storyblokEntry
  const content = JSON.parse(story.content)

  return(
    <Layout>
      <SEO title="Home" />
      <AccessWrapper introString='Eva Funk' content={content.artists[6]} id={6}>
        <Eva content={content} />
      </AccessWrapper>
    </Layout>
  )
}

export default EvaFunk
// ($slug: String!)

export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`