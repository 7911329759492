// const A3 = {
//     width: 0.297,
//     height: 0.42,
//   }
  
  const A1 = {
    width: 0.594,
    height: 0.84,
  }
  
  // const A0 = {
  //   height: 1.189,
  //   width: 0.841,
  // }
  
  
  const poster = {
    ...A1
  }

  
const pole = {
  height: 3,
  radius: 0.5,
  segRadius: 1000
}



  export default { poster, pole }