import React, { useRef, useState } from "react"
import { extend, useThree, useFrame } from '@react-three/fiber'
// import { extend, useThree, useFrame } from 'react-three-fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

extend({ OrbitControls })

const Controls = ({ minPolarAngle, maxPolarAngle, enableZoom, position }) => {
    // console.log("controls")

    const orbitRef = useRef()
    const { camera, gl } = useThree()
    camera.position.set(0,2.6,0.4)

    useFrame(() => {
        orbitRef.current.update()

    })

    return(
        <orbitControls 
            args={[camera, gl.domElement]}
            ref={orbitRef}
            minPolarAngle={minPolarAngle}
            maxPolarAngle={maxPolarAngle}
            // enableZoom={true}
            enableZoom={false}
            position={position ? position : [0, 100, 100]}
            // autoRotate
        />
    )
}

export default Controls
