const bendShape = (posArray, heightSegments, widthSegments, radius, direction, callback) => {

  const hVerts = heightSegments + 1;
  const wVerts = widthSegments + 1;
  const posterSegAngle = 360 / (wVerts-1)
  const segRad = posterSegAngle * Math.PI / 180
  let increase = 0

  for (let j = !direction ? 0 : hVerts; !direction ? j < hVerts : j > 0; !direction ? j++ : j--) {
    const index = j

    increase = callback(increase, index)

    const distanceToCenter = radius + increase
    // const distanceToCenter = Math.sin(j) * radius

    console.log(wVerts)
    for (let i = 0; i < wVerts; i++) {
    // for (let i = !direction ? 0 : wVerts; !direction ? i < wVerts : i > 0; !direction ? i++ : i--) {
      // Shifting along the circumference
      const currentSegment = i+1
      const alpha = segRad * currentSegment
      const x = Math.cos(alpha) * distanceToCenter
      // const x = Math.cos(alpha) * Math.sin(distanceToCenter)
      const y = Math.sin(alpha) * distanceToCenter
      // const y = Math.sin(alpha) * Math.sin(distanceToCenter)
      const vertice = 3 * (j * wVerts + i)
      posArray[vertice + 2] = x
      posArray[vertice] = y
    }
  }

  return posArray
}

export default bendShape