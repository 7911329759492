import React, { useLayoutEffect, useRef } from "react"

// import { 
    // useUpdate,
    // useLoader
// } from '@react-three/fiber'
// } from 'react-three-fiber'

import * as THREE from 'three'

import bendShape from './bendShape'

const c = {
  width_segments: 500,
  height_segments: 100
}

const Base = ({ position, direction, defineShape, bumpMap, alphaMap, radius }) => {


  const ref = useRef();
  // –––––––– OLD
  // const ref = useUpdate(({geometry}) => {
  //   let pos = geometry.getAttribute("position")
  //   const params = geometry.parameters
  //   // const radius = 0.5

  //   pos.array = bendShape(pos.array, params.heightSegments, params.widthSegments, radius, direction, defineShape)

  //   pos.needsUpdate = true;
  //   geometry.attributes.position.needsUpdate = true;

  // }, [])
  // –––––––– NEW
  const update = () => {
    const geometry = ref.current.geometry;
    let pos = geometry.getAttribute("position")
    const params = geometry.parameters
    // const radius = 0.5

    pos.array = bendShape(pos.array, params.heightSegments, params.widthSegments, radius, direction, defineShape)

    pos.needsUpdate = true;
    geometry.attributes.position.needsUpdate = true;
  }

  useLayoutEffect(() => {
    update()
  }, [])
  // –––––––– NEW

  return(
    <mesh 
    ref={ref} 
    position={position}
    castShadow
    receiveShadow
    >
      <planeBufferGeometry 
      attach="geometry" 
      args={[c.width, 0.1, c.width_segments, c.height_segments]} 
      />
      <meshPhongMaterial 
        attach="material" 
        // color="rgb(139,107,51)" 
        color="rgb(149,117,61)" 
        metalness={10} 
        roughness={10} 
        shininess={30}
        // specular="rgb(143,120,80)"
        specular="rgb(153,130,90)"
        emissive={0x0}
        bumpScale={0.05}
        fog={true}
        flatShading={true}
        // combine={THREE.AddOperation}
        reflectivity={0.1}
        bumpMap={bumpMap}
        bumpScale={0.01}
        alphaMap={alphaMap ? alphaMap : false}
        transparent={true}
        // side={THREE.DoubleSide}
 
      />
    </mesh>
  )
}

export default Base
