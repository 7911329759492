import React, { useRef, useMemo } from "react"

// import { a } from 'react-spring/three'
import * as THREE from 'three'

import config from './config'

const Pole = ({ rotation, background, position, bumpMap }) => {

  const mesh = useRef()
  
  // Calculate Canvas Size
  const size = [config.pole.radius, config.pole.radius, config.pole.height, config.pole.segRadius]

  if(mesh.current && rotation) mesh.current.rotation.x = rotation

  const texture = useMemo(() => new THREE.TextureLoader().load(background), [background] )
  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;
  texture.format = THREE.RGBFormat;
  texture.needsUpdate = true;
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(2, 2);
  // texture.rotation = 0.2

  return (
    <mesh
    position={position}
    ref={mesh}
    rotateX
    >
      <cylinderBufferGeometry attach="geometry" args={size} />
      <meshPhongMaterial
        attach="material"
        color={"rgb(230,230,230)"}
        specular={"rgb(225,225,225)"}
        roughness={10} 
        shininess={0}
        side={THREE.DoubleSide}
        map={texture}
        bumpMap={bumpMap}
        bumpScale={0.007}
      />
    </mesh>
  )
}
  export default Pole