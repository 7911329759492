import React, { useState } from "react"
import Scene from './sceneEva'
import Whitebox from './whitebox'
// import './eva-funk.scss'

const EvaFunk = ({content}) => {

    const boxProps = {
      position: [0, 0, 0],
      size: [4, 2, 0.03],
    }

    const [ state, setState ] = useState({
      pointerOverPoster: false,
      data: false
    })

    const handlePointerOver = (data) => {

      // console.log("pointer over, updatestate")
      setState({
        ...state,
        pointerOverPoster: true,
        data: data,
      })
    }
    console.log("state")
    console.log(state)

    return(
      <div className="canvas__wrapper">
        <Scene boxProps={boxProps} content={content} handlePointerOver={handlePointerOver}/>
        <Whitebox state={state}/>
      </div>
    )
}

export default EvaFunk

