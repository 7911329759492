import config from './config'

const bend = (posArray, heightSegments, widthSegments, position, overlay, radius) => {

    const hVerts = heightSegments + 1;
    const wVerts = widthSegments + 1;
    const distanceToCenter = radius
    // const radius =
    const u = 2*Math.PI*distanceToCenter
    const posterAufU = config.poster.width
    // GetPosterarea angle
    const posterAngle = 360 / u * posterAufU
    // Poster SegmentAngle
    const posterSegAngle = posterAngle / wVerts
    // Get Radiant from Degree
    const segRad = posterSegAngle * Math.PI / 180
  
    const zIndex = overlay ? overlay/1000 : 0
    const offsetPOster = 1.02 - zIndex
  
    for (let j = 0; j < hVerts; j++) {
  
      for (let i = 0; i < wVerts; i++) {
  
        // Shifting along the circumference
        const currentSegment = position && position.x ? i+1+position.x*100 : i+1
        const alpha = segRad * currentSegment
        // console.log("cos alpha", Math.cos(alpha))
        const x = Math.cos(alpha) * distanceToCenter*offsetPOster
        // const y = alpha ? 0.5 / Math.sin(alpha) : 0 
        const y = Math.sin(alpha) * distanceToCenter*offsetPOster
        // const x = Math.cos(alpha) / hypothenuse
        const vertice = 3 * (j * wVerts + i)
        posArray[vertice + 2] = x
        posArray[vertice ] = y
        // posArray[vertice] = 0+i*0.05 // THIS WORKS BUT WRONG VALUE
        if(position && position.y) posArray[vertice + 1] += position.y 
        // posArray[vertice + 1] += 1
      }
    }

    return posArray
  }

  export default bend