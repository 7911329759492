import React, { useMemo } from "react"
import { 
    Canvas,
    extend 
} from '@react-three/fiber'
import * as THREE from 'three'


// WRONG CONTROLS?? CHECK GITLAB HISTORY
import Controls from './controls'
import Plane from './plane'
import Base from './base'
import Pole from './pole'
// import './eva-funk.scss'
extend( 'OES_texture_half_float' );
extend( 'OES_texture_half_float_linear' );

// CONVERT TO DRACO :
// gltf-pipeline -i saeule_oben.gltf -o saeule_obenDraco.gltf -d

const defineTop = (increase, index) => {
  if(index > 10 && index < 35) increase += index/index/450
  if(index === 50) increase += 0.005
  if(index > 75 && index < 100) increase += index/index/450

  return increase
}
const defineBase = (increase, index) => {
  if(index === 0) increase += 0.002
  if(index < 3) increase += 0.002
  if(index < 40) increase += index*index/800000
  if(index === 40) increase += 0.015
  if(index > 40 && index < 43) increase += 0.001
  if(index === 86) increase += 0.01

  return increase
}
const defineCrown = (increase) => increase

const createTexture = (texture, repeat) => {
  texture.repeat.set( repeat[0], repeat[1] );
  texture.repeat.x = repeat[0];
  texture.repeat.y = repeat[1];
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.needsUpdate = true;

  return texture
}

// const Scene =  React.memo(({ boxProps, posters, handlePointerOver }) => {
class Scene extends React.Component{
  constructor(props){
    super(props)
  }

  // SHOULD UPDATE AFTER OBJECT LOADED!
  shouldComponentUpdate = () => false

  render(){

    let pixelRatio = typeof window !== 'undefined' && window.devicePixelRatio ? window.devicePixelRatio : 1
    // let pixelRatio = 1
    // console.log(pixelRatio)

    return(
      <>
        <Canvas 
        width="auto"
        height="100vh"
        className="canvas"
        background="red"
        >
          <Three {...this.props} objectLoaded={this.objectLoaded}/>
        </Canvas>
        {/* <style>
          {`
            .canvas{
              overflow: visible;
            }

            .canvas canvas{
              position: relative;
              // width: ${100*pixelRatio}vw;
              height: ${100*pixelRatio}vh;
              transform: scale(${1/pixelRatio}, ${1/pixelRatio});
              transform-origin: 50% 0;
            }
          `}
        </style> */}
      </>
    )
  }
}


const Three = ({ content, boxProps, handlePointerOver, objectLoaded }) => {

  const { posters, evabackground, panorama } = content
  // console.log(content)
  
  const posterBumpMapUrl = content.evapaperbumpmap.filename
  const posterBumpMap = createTexture(useMemo(() => new THREE.TextureLoader().load(posterBumpMapUrl), [posterBumpMapUrl] ), [1,1])
  const goldBumpMapUrl = content.evagoldbumpmap.filename
  const goldBumpMap = createTexture(useMemo(() => new THREE.TextureLoader().load(goldBumpMapUrl), [goldBumpMapUrl] ), [2,2])
  const crownAlphaMapUrl = content.evacrownalphamap.filename
  const crownAlphaMap = createTexture(useMemo(() => new THREE.TextureLoader().load(crownAlphaMapUrl), [crownAlphaMapUrl] ), [18,1])
  const crownAlphaMapOutherUrl = content.evacrownalphamapouther.filename
  const crownAlphaMapOuther = createTexture(useMemo(() => new THREE.TextureLoader().load(crownAlphaMapOutherUrl), [crownAlphaMapOutherUrl] ), [18,1])

  // const meshRef = useRef()

  // useFrame(() => {
  //   if(!meshRef && !meshRef.current) return false
  //   meshRef.current.rotation.x = meshRef.current.rotation.x += 0.01
  // })

  const handleClickOnPole = (e) => {
    e.stopPropagation()
  }

  return(
    <>
      <Controls minPolarAngle={Math.PI / 1.75} maxPolarAngle={Math.PI / 1.75}/>
      <ambientLight intensity="0.2"/>
      <pointLight position={[2, 1, 0]} color="white" intensity="0.5" visible shadowMapVisible />
      <pointLight position={[-2, 1, 0]} color="white" intensity="0.3" visible shadowMapVisible />

      <Base 
      bumpMap={goldBumpMap}
      alphaMap={crownAlphaMap}
      position={[0, 1.85, 0]}
      radius={0.5}
      direction={true}
      height={0.1}
      defineShape={defineCrown}
      />
      <Base 
      bumpMap={goldBumpMap}
      alphaMap={crownAlphaMapOuther}
      position={[0, 1.85, 0]}
      radius={0.51}
      direction={true}
      height={0.1}
      defineShape={defineCrown}
      />
      <Base 
      bumpMap={goldBumpMap}
      position={[0, 1.65, 0]}
      radius={0.5}
      direction={true}
      height={0.1}
      defineShape={defineTop}
      />
      <Base 
      bumpMap={goldBumpMap}
      position={[0, -1.35, 0]}
      radius={0.5}
      direction={false}
      height={0.1}
      defineShape={defineBase}
      />
      
      <Pole 
      bumpMap={posterBumpMap}
      position={[0, 0.15, 0]}
      onClick={handleClickOnPole}
      background={evabackground.filename} />
      {
        posters.map((poster, i) => {

          return(
            <Plane 
            bumpMap={posterBumpMap}
            key={i} 
            overlay={i} 
            position={{ y: Math.random()/2, x: i+Math.random()/2}} 
            poster={poster} 
            onPointerOver={handlePointerOver}
            />
          )
        })
      }
    </>
  )
}

export default Scene