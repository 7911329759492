import React from "react"
import AudioPlayer from '../../elements/audioPlayer'
import style from './whitebox.module.scss'

const WhiteBox = ({state}) => {

  // console.log("state.data.text")
  // console.log(state.data)
  if(!state.data) return false
  // console.log(state)
  let text = ""
  if(state.data && state.data.text && state.data.text.content)  state.data.text.content.forEach((item) => {
    // console.log("item")
    // console.log(item)
    if(item.content && item.content[0]){

      text += `${item.content[0].text ? item.content[0].text : ""}<br/>`
    }else{
      text += `<br/>`
    }
    // text += `<br/>`
  })

  return(
    <div>
      { 
        state.pointerOverPoster ?
        <div>
          <div className={style.whitebox}>
            <p dangerouslySetInnerHTML={{__html: text}} />
          </div>
          <div className={style.audio}>
            <AudioPlayer file={state.data.audio.filename} />
          </div>
          {/* <audio autoPlay controls>
            <source src={state.data.audio.filename} type="audio/mpeg" />
          </audio> */}
        </div>
        : false
      }
    </div>
  )
}

export default WhiteBox