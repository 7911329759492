import React, { useRef, useLayoutEffect, useMemo, useState } from "react"

// import { 
//   useFrame,
//     useUpdate
// } from '@react-three/fiber'
// import { a, useSpring } from '@react-spring/three';
import * as THREE from 'three'
import bend from './bend'
import config from './config'

const c = {
  width: config.poster.width,
  height: config.poster.height,
  width_segments: 28,
  height_segments: 5
}

const Plane = ({ imageRef, position, poster, overlay, onPointerOver, onPointerOut, bumpMap }) => {
  
    const texture = useMemo(() => new THREE.TextureLoader().load(poster.image.filename), [poster.image.filename] )
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.format = THREE.RGBFormat;
    texture.needsUpdate = true;
  
    // const ref = useUpdate(({geometry}) => {
    //   let pos = geometry.getAttribute("position")
    //   const params = geometry.parameters
  
    //   pos.array = bend(pos.array, params.heightSegments, params.widthSegments, position, overlay, config.pole.radius)
  
    //   pos.needsUpdate = true;
    //   geometry.attributes.position.needsUpdate = true;
  
    // }, [])

  const ref = useRef();

  // –––––––– NEW
  const update = () => {
    const geometry = ref.current.geometry;
    let pos = geometry.getAttribute("position")
    const params = geometry.parameters
    // const radius = 0.5
      pos.array = bend(pos.array, params.heightSegments, params.widthSegments, position, overlay, config.pole.radius)

    // pos.array = bendShape(pos.array, params.heightSegments, params.widthSegments, radius, direction, defineShape)

    pos.needsUpdate = true;
    geometry.attributes.position.needsUpdate = true;
  }

  useLayoutEffect(() => {
    update()
  }, [])
  // –––––––– NEW
  
    const [ active, setActive ] = useState(0)

    // const { spring } = useSpring({
    //   spring: active,
    //   config: { 
    //     mass: 5, 
    //     tension: 400, 
    //     friction: 50, 
    //     precision: 0.0001 },
    // })

    // const color = spring.to([0, 1], ['rgba(255,255,255,1)', 'rgba(255,255,50,1)'])

    const handleOnClick = (e) => {
      e.stopPropagation()
      onPointerOver(poster)
    }

    const handlePointerOver = (e) => {
      e.stopPropagation()
      setActive(1)
      if(document) document.body.style.cursor = "pointer"
    }

    const handlePointerOut = (e) => {
      e.stopPropagation()
      setActive(0)
      if(document) document.body.style.cursor = null
    }


    return(
      <mesh  
      ref={ref}
      // onClick={handleOnClick}
      // onTouchStart={handleOnClick}
      onPointerDown={handleOnClick}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
      >
        <planeBufferGeometry 
        attach="geometry" 
        args={[c.width, c.height, c.width_segments, c.height_segments]} 
        />
        <meshPhongMaterial
        ref={imageRef}
        attach="material"
        map={texture}
        color={active ? {r:5, g: 5, b:5 } : {r:1, g: 1, b:1 } }
        side={THREE.DoubleSide}
        needsUpdate={true}
        bumpMap={bumpMap}
        bumpScale={0.01}
        />
      </mesh>
    )
  }
  
  export default Plane
